import React from 'react';
import Img from 'gatsby-image';

export default function Template({
  post // this prop will be injected by the GraphQL query below.
}) {
  const { frontmatter, html } = post;
  return (
    <div className="blog-post-container">
      <div className="blog-post">
        <h3>{frontmatter.date}</h3>
        <h2>{frontmatter.subject}</h2>
        <div style={{ textAlign: 'center' }}>
          <img src={require(`../archive/${frontmatter.hero}`)} />
        </div>
        {/*<Img sizes={frontmatter.hero.childImageSharp.sizes} />*/}
        <div className="blog-post-content" dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    </div>
  );
}
