import React from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import { Link } from 'gatsby';

import Layout from 'components/Layout';
import Container from 'components/Container';
import Template from 'components/postTemplate';

class Archive extends React.Component {
  render() {
    const { data } = this.props;
    const posts = data.allMarkdownRemark.edges;

    return (
      <Layout pageName="archive">
        <Helmet>
          <title>Archiv</title>
        </Helmet>
        <Container>
          <div className="archiv_container">
            <h1>Das Archiv</h1>
            <p>
              Hier findest du in chronologischer Reihenfolge unser Newsletter-Archiv. Im <Link to="/faq/">FAQ</Link>{' '}
              beantworten wir außerdem die häufigsten Fragen.
            </p>
            {posts.map(({ node }) => {
              return <Template key={node.id} post={node} />;
            })}
          </div>
        </Container>
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/archive/" } }
      sort: { order: DESC, fields: [frontmatter___issue] }
    ) {
      edges {
        node {
          id
          html
          frontmatter {
            issue
            subject
            date(formatString: "DD.MM.YYYY")
            hero
          }
        }
      }
    }
  }
`;

export default Archive;
